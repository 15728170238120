const { is } = Tui.utils

export default {
  name: 'TStatusBarItem',
  data () {
    return {
      symbol: 'BRL',
      itemClass: 'tui tuim item status-item color',
      icon: ''
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.icon = this.getIcon(this.item.icon)
    this.symbol = this.getSymbol(this.item.symbol)
    this.itemClass = this.getItemClass(this.item.itemClass)
  },
  methods: {
    getSymbol (symbol) {
      return is('undefined', symbol) ? this.symbol : symbol
    },
    getIcon (icon) {
      return is('undefined', icon) ? this.icon : icon
    },
    getItemClass (itemClass) {
      const _class = is('undefined', itemClass) ? `${this.itemClass} default` : `${this.itemClass} ${itemClass}`
      return this.icon ? `${_class} has-icon` : _class
    }
  }
}
