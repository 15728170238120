var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.itemClass },
    [
      _vm.loading
        ? _c(
            "span",
            [
              _c("t-placeholder", { attrs: { lines: 1, paragraph: true } }),
              _c("t-placeholder", {
                attrs: { lines: 1, header: true, paragraph: false }
              }),
              _c("t-placeholder", { attrs: { lines: 1, paragraph: true } })
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && _vm.icon
        ? _c("t-icon", {
            staticClass: "tui tuim icon",
            style: { color: "#e2e2e3", width: "15px", height: "15px" },
            attrs: { icon: _vm.icon }
          })
        : _vm._e(),
      !_vm.loading && _vm.item.label
        ? _c("span", {
            staticClass: "label",
            domProps: { innerHTML: _vm._s(_vm.item.label) }
          })
        : _vm._e(),
      !_vm.loading && _vm.item.label
        ? _c("span", {
            staticClass: "value",
            domProps: { innerHTML: _vm._s(_vm.item.value) }
          })
        : _vm._e(),
      !_vm.loading && _vm.item.label
        ? _c("span", {
            staticClass: "symbol",
            domProps: { innerHTML: _vm._s(_vm.symbol) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }